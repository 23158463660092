@import "../../../assets/typography/_variables.scss";

.noise {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../../assets/images/noise.gif");
  opacity: 0.08;
  z-index: 1;
}

.container {
  position: relative;
  color: $primary-text-color-dark;
  width: 100%;
  margin: auto;

  .itemContainer {
    height: 100vh;
    position: relative;

    .item {
      font-size: 10rem;
      text-shadow: 3px 3px 5px #8f4900;
      position: absolute;
      top: 30%;
      color: #ff850c;

      .textContainer {
        float: left;
        padding: 1em;
      }

      .short {
        width: 100%;
        display: block;
        font-size: 2em;
      }


      .content {
        background: #444;
        float: left;
        width: 100%;
        height: 100%;
      }
    }

    .item:nth-child(even) {

      img {
        float: right;
      }

      .content {
        background: #3e8e42;
      }
    }
  }
}

.headerContainer {
  display: flex;
  justify-content: center;
}

.header {
  background: linear-gradient(
                  rgba(0, 0, 0, 0.2),
                  rgba(0, 0, 0, 0.2)
  ),
  url("../../../assets/images/snow.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  color: $primary-text-color;
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.quoteContainer {
  font-size: 12rem;
  color: #fbdc80;
  text-shadow: 0 0 15px #ffd764;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  padding: 1rem;
  z-index: 2;
  position: relative;
}

@media (max-width: 900px) {
  .quoteContainer {
    font-size: 6rem;
  }
}
