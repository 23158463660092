@import "../../../assets/typography/_variables.scss";

.noise {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../../assets/images/noise.gif");
  opacity: 0.08;
  z-index: 1;
}

.container {
  position: relative;
  color: $primary-text-color-dark;
  width: 100%;
  margin: auto;

  .itemContainer {
    height: 100vh;
    position: relative;

    .item {
      font-size: 10rem;
      text-shadow: 3px 3px 5px #8f4900;
      position: absolute;
      top: 30%;
      color: #ff850c;

      .textContainer {
        float: left;
        padding: 1em;
      }

      .short {
        width: 100%;
        display: block;
        font-size: 2em;
      }


      .content {
        background: #444;
        float: left;
        width: 100%;
        height: 100%;
      }
    }

    .item:nth-child(even) {

      img {
        float: right;
      }

      .content {
        background: #3e8e42;
      }
    }
  }
}

@media (max-width: 900px) {
  .container .itemContainer .item {
    font-size: 3rem;
  }

  .spacer {
    padding: 1rem;
    grid-template-columns: repeat(1, 1fr) !important;

    .card {
      font-size: 2rem;

      .innerTitle {
        font-size: 3rem;
      }
    }
  }
}


@keyframes shadow-animate {
  0% {
    background-position: -400%;
  }
  100% {
    background-position: 400%;
  }
}

.headerContainer {
  display: flex;
  justify-content: center;
}

.header {
  background: linear-gradient(
                  rgba(0, 0, 0, 0.2),
                  rgba(0, 0, 0, 0.2)
  ),
  url("../../../assets/images/food.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  color: $primary-text-color;
  width: 100%;
  height: calc(100vh - 4rem);
  margin: 1rem;
  position: relative;
  overflow: hidden;
  max-width: 60rem;
  padding: 1rem 0;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  border-radius: 0.5rem;
}

.welcome {
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  font-size: 2rem;
  position: absolute;


  background: linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,1), rgba(255,255,255,0));
  background-repeat: no-repeat;
  background-size: 80%;
  animation: shadow-animate 5s linear;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
  background-position: -400%;
  z-index: 1;
  white-space: nowrap;
  height: 3rem;


  &.faded {
    color: rgba(0,0,0,0);
  }
}
